<template>
  <div class="c-app flex-row align-items-center">
    <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
    <CContainer align="center">
      <CSpinner
        v-if="isLoading"
        color="info"
        style="width:3rem;height:3rem;"
      />
      <CCol xl="5" v-if="!isLoading">
        <img :src="url" class="center mb-1" width="40%" alt="Logo"/>
        <h1>{{ company_name }}</h1>
        <CCard class="p-4">
          <CCardBody>
            <p class="login-box-msg">Sign in to start your session</p>
            <CForm v-on:submit.prevent="getLogin">
              <div class="input-group mb-3">
                <input type="email" class="form-control" v-bind:value="form.email" v-on:input="form.email = $event.target.value" placeholder="Email">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <v-icon name="envelope"/>
                  </div>
                </div>
                <small class="form-text text-muted w-100 text-left">{{errors.email}}</small>
              </div>
              <div class="input-group mb-3">
                <input type="password" class="form-control" v-bind:value="form.password" v-on:input="form.password = $event.target.value" placeholder="Password">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <v-icon name="lock"/>
                  </div>
                </div>
                <small class="form-text text-muted w-100 text-left">{{errors.password}}</small>
              </div>
              <div class="row">
                <div class="col-8 text-left">
                  <div class="icheck-primary" style="display: none;">
                    <input type="checkbox" id="remember">
                    <label for="remember" class="ml-1 font-weight-bold">
                      Remember Me
                    </label>
                  </div>
                </div>
                <!-- /.col -->
                <div class="col-4">
                  <CButton type="submit" color="info" class="btn-block">Sign In</CButton>
                </div>
                <!-- /.col -->
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {},
  data () {
    return {
      isLoading: false,
      url: 'img/company/cirrus.png',
      company_name: 'Cirrus Pro',
      generals: [],
      form: {
        email: '',
        password: ''
      },
      errors: {
        email: '',
        password: '',
        message: ''
      }
    }
  },
  created() {
    this.getInfoWeb()
  },
  methods: {
    ...mapActions('auth', ['authin']),
    ...mapActions('information', ['infoCC']),
    async getLogin() {
      this.isLoading = true
      await this.authin(this.form).then((response) => {
        return this.$router.push('/')
        this.isLoading = false
      })
      .catch((error) => {
        this.getError(error)
        this.isLoading = false
      })
    },
    async getInfoWeb() {
      this.isLoading = true
      await this.infoCC().then((response) => {
        this.generals = response.length > 0 ? response : []
        var d_cn = this.generals.findIndex(obj => obj.name ==="cc_company_name")
        var d_l = this.generals.findIndex(obj => obj.name ==="cc_logo")
        var companyname = this.generals[d_cn]?this.generals[d_cn].value:this.company_name
        this.company_name = companyname?companyname:this.company_name
        document.title = this.company_name
        var logourl = this.generals[d_l]?this.generals[d_l].value:this.url
        this.url = logourl?logourl:this.url
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    getError(error){
      var data = error.data
      if(typeof(data) == 'object' && data){ if(data.errors) { 
          this.errors.email = data.errors.email ? data.errors.email[0] : ''
          this.errors.password = data.errors.password ? data.errors.password[0] : ''
      } else { this.errors = { email: '', password: '' }} }
      this.errors.message = data.message ? ' - '+data.message : ''
      this.flashMessage.error({
          title: 'Error',
          message: error.statusText+this.errors.message
      })
    }
  }
}
</script>
